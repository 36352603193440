import React, { Component } from 'react'
import styled, { css } from 'react-emotion';
import {
  MdExpandMore,
  MdExpandLess,
  MdDone
} from 'react-icons/md';


const DDWrapper = styled('div')`
  user-select: none;
  position: relative;
  width: 300px;
`;

const DDHeader = styled(`div`)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 38px;
  border: 1px solid rgb(223, 223, 223);
  border-radius: 3px;
  cursor: default;
  position: relative;
  background-color: white;
`;

const DDList = styled('ul')`
    z-index: 10;
    position: absolute;
    width: 100%;
    border: 1px solid rgb(223, 223, 223);
    border-top: none;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: white;
    box-shadow: 0 2px 5px -1px rgb(232, 232, 232);
    font-weight: 700;
    padding: 15px 0;
    max-height: 215px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
`;

const DDListItem = styled('li')`
  width: 100%;
  font-size: 1.5rem;
  padding: 8px 10px;
  line-height: 1.6rem;
  cursor: default;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    color: white;
    background-color: #FFCC01;
  }
`;

const ddlSelectedCss = css`
  color: white;
  background-color: #FFCC01;
`

class DropdownMultiple extends Component{
  constructor(props){
    super(props)
    this.state = {
      listOpen: false,
      headerTitle: this.props.title,
      timeOut: null
    }
    this.close = this.close.bind(this)
  }

  componentDidUpdate(){
    const { listOpen } = this.state
    setTimeout(() => {
      if(listOpen){
        window.addEventListener('click', this.close)
      }
      else{
        window.removeEventListener('click', this.close)
      }
    }, 0)
  }

  componentWillUnmount(){
    window.removeEventListener('click', this.close)
  }

  close(timeOut){
    this.setState({
      listOpen: false
    })
  }

  static getDerivedStateFromProps(nextProps){
    const selected = nextProps.list.filter(function(a) { return a.selected; });
    const count = selected.length;
    let headerTitle = ""
    if(count === 0){
      headerTitle = nextProps.title
    }
    else if(count === 1){
      headerTitle = `Filtering by ${count} ${nextProps.titleHelper}`
    }
    else if(count > 1){
      headerTitle = `Filtering by ${count} ${nextProps.titleHelper}s`
    }
    if (nextProps.titleShowSelections && count > 0) {
      headerTitle = `${headerTitle} (${selected.map(a => a.title).join(', ')})`
    }
    return {headerTitle};
  }

  resetAll() {
    const{list, toggleItem } = this.props
    list.forEach((item, index) => { item.selected && toggleItem(index) })
  }

  toggleList(){
    this.setState(prevState => ({
      listOpen: !prevState.listOpen
    }))
  }

  render(){
    const{list, toggleItem, resetCaption} = this.props;
    const{listOpen, headerTitle} = this.state;
    const hasSelections = list.some(i => i.selected);

    return(
      <DDWrapper>
        <DDHeader onClick={() => this.toggleList()}>
            <div className="dd-header-title" css={css`
              font-weight: 300
              margin: 2px 20px
              margin-right: 30px`}
              >{headerTitle}</div>
            {listOpen
              ? <MdExpandLess css={css`margin-right: 20px;`}/>
              : <MdExpandMore css={css`margin-right: 20px;`}/>
            }
        </DDHeader>
       {listOpen && <DDList className="dd-list" onClick={e => e.stopPropagation()}>
          {resetCaption &&
          <DDListItem className="dd-list-item" css={!hasSelections && ddlSelectedCss} key={resetCaption} onClick={() => this.resetAll()}>
             {resetCaption} {!hasSelections && <MdDone />}
          </DDListItem>
          }
         {list.map((item, index) => (
           <DDListItem className="dd-list-item" css={item.selected && ddlSelectedCss} key={item.title} onClick={() => toggleItem(index)}>
             {item.title} {item.selected && <MdDone />}
           </DDListItem>
          ))}
        </DDList>}
      </DDWrapper>
    )
  }

}

export default DropdownMultiple