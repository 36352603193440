export const getAvailableProducts = (products, filters = []) => {
  return products.filter(({node: product}) => (
    !((product.tags || []).includes('hidden')) && filterProducts(product, filters)
  ))
}

const filterProducts = (product, filterSet) => {
  // handle empty filter
  console.log("filterstart", filterSet)
  if (filterSet.length == 0) return true

  for (let filter of filterSet) {
    let filterObj = product[filter.filterKey]
    let filterVal = filter.filterValue
    let matchesFilter = false
    if (Array.isArray(filterObj)) {
      matchesFilter = filterObj.includes(filterVal)
    } else if (filterObj) {
      matchesFilter = filterObj == filterVal
    } else {
      console.error(`Filter key ${filter.filterKey} is invalid or empty.`)
    }
    if (matchesFilter) return true
  }
  console.log("filterfail", filterSet)
  return false
}

export const featuredProducts = (products) => {
  return products.filter(({node: product}) => (
    (product.tags || []).includes('featured')
  ))
}

export const regularProducts = (products) => {
  return products.filter(({node: product}) => (
    !(product.tags || []).includes('featured')
  ))
}
