import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled, { css } from 'react-emotion';

import ProductListingHeader from './ProductListingHeader';
import ProductListingContainer from './ProductListingContainer';
import InterfaceContext from '../../context/InterfaceContext';
const ProductListing = () => (
  <StaticQuery
    query={graphql`
      query ProductListingQuery {
        products: allShopifyProduct(
          sort: { fields: [publishedAt], order: ASC },
        ) {
          edges {
            node {
              id
              handle
              title
              description
              descriptionHtml
              productType
              tags
              vendor
              variants {
                shopifyId
                title
                price
                availableForSale
              }
              images {
                id
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 910, maxHeight: 910) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ products }) => (
      <InterfaceContext.Consumer>
        {({ vendor, vendorConfig }) => {
          return (
            <>
              {/* <ProductListingHeader
                welcome={vendorConfig.messages.en.storeWelcome}
                intro={vendorConfig.messages.en.storeIntro}
                />
              <ProductListingContainer products={products.edges.filter(({ node: product}) => product.vendor == vendor)} /> */}
              <ProductListingHeader
                welcome="Papadamn Store is temporarily closed."
                intro="Hello Dear Supporter, due to various reasons we are having difficulties managing the logistics of the public merch store. We will be temporarily closing in order to build a shopping experience that we think our fans should deserve. Please message our page if you have any questions. Keep in touch!"
                />
              <ProductListingContainer products={products.edges.filter(({ node: product}) => product.vendor == vendor)} />
            </>
          )
        }}
      </InterfaceContext.Consumer>
    )}
  />
);

export default ProductListing;
