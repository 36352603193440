import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from 'react-emotion';
import { breakpoints, spacing } from '../../utils/styles';
import ProductListingItem from './ProductListingItem';
import DropdownMultiple from './DropdownMultiple';
import {getAvailableProducts, featuredProducts, regularProducts} from '../../utils/productFilter';

const productListingContainerStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${spacing.lg}px;

  @media (min-width: ${breakpoints.desktop}px) {
    flex-direction: row;
    flex-wrap: wrap;
    padding: ${spacing['2xl']}px;
  }
`;

class ProductListingContainer extends Component {

  state = {
    filterOpts: [
      {
        title: 'Print',
        filterKey: 'productType',
        filterValue: 'Print',
        selected: false
      },
      {
        title: 'T-Shirt',
        filterKey: 'productType',
        filterValue: 'T-Shirt',
        selected: false
      },
    ]
  };


  toggleFilterOpt = (id) => {
    let temp = this.state.filterOpts;
    temp[id].selected = !temp[id].selected
    this.setState({
      filterOpts: temp
    })
  }

  render() {
    const { products } = this.props;
    const { filterOpts } = this.state;
    const selectedFilters = filterOpts.filter((opt) => opt.selected)
      .map(({filterKey, filterValue}) => {return {filterKey, filterValue}})
    const availableProducts = getAvailableProducts(products, selectedFilters);

    return (
      <>
        {/* <div className={css`margin-left: auto; margin-right: auto;`}>
            <DropdownMultiple
              titleHelper="type"
              titleShowSelections={true}
              title="Filter by type"
              resetCaption="Everything"
              list={filterOpts}
              toggleItem={(id) => this.toggleFilterOpt(id)}
            />
        <div>Displaying {availableProducts.length} products.</div>
        </div> */}
        <div className={productListingContainerStyle}>
        {featuredProducts(availableProducts).map(({ node: product }) => (
            <ProductListingItem key={product.id} product={product} />
          ))}
        {regularProducts(availableProducts).map(({ node: product }) => (
          <ProductListingItem key={product.id} product={product} />
        ))}
        </div>
      </>
    );
  }
}

ProductListingContainer.propTypes = {
  products: PropTypes.array.isRequired,
};

export default ProductListingContainer;
